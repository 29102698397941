import React from 'react';
import myself from './myself.jpg';
import github from './github.png';
import linkedin from './Linkedin.jpg';
import twitter from './twitter.jpg'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={myself} className="App-logo" alt="logo" />
        <p>
          Welcome to my website ! It isn't finished yet. You can still find me on social media !
        </p>
        <p>
          Email :
          <a
            className="App-link"
            href="mailto:hireme@clementparmentier.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            hireme@clementparmentier.com
          </a><br/>
          Phone : <code>0492 75 07 92</code>
        </p>
        <p>
          <a
            className="App-link"
            href="https://github.com/erzzoin/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={github} width="40" height="40"/>
          </a>
          <a
            className="App-link"
            href="https://www.linkedin.com/in/cl%C3%A9ment-parmentier-4b18a287/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={linkedin} width="40" height="40"/>
          </a>
          <a
            className="App-link"
            href="https://twitter.com/ParmentierClme2"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={twitter} width="40" height="40"/>
          </a>
        </p>
      </header>
    </div>
  );
}

export default App;
